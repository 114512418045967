import React, { useState } from 'react';
import BarcodeScannerComponent from 'react-webcam-barcode-scanner';

import './App.css';

const App = () => {
	let audio = new Audio('./scan-sound.mp3');
	const [data, setData] = useState('Not Found');

	const handleUpdate = (err, result) => {
		console.log(err);
		console.log(result);
		if (result) setData(result.text);
		else setData('Not Found');
	};

	const start = () => {
		audio.play();
	};
	console.log(process.env.PUBLIC_URL);
	return (
		<div className='App'>
			<BarcodeScannerComponent width={600} height={200} onUpdate={handleUpdate} />
			<p>{data}</p>
			<button onClick={start}>Play</button>
		</div>
	);
};

export default App;
